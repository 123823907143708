.countryPhone {
    width: 100%;

    .react-tel-input {
        border: none;
        width: 100%;

        .form-control {
            height: 2.6rem !important;
            padding-left: 48px !important;
        }
    }

    input {
        border-radius: 8px !important;
        padding: 0.4rem 0.7rem;
        width: 100% !important;
        height: auto !important;
        outline: none;
        border: 1px solid #bbbcbd !important;
        font-family: 'Urbanist', sans-serif !important;
        transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);

        &:active,
        &:focus {
            box-shadow: 0 0 0 1px #ff6601;
        }

        &::placeholder {
            color: #e0e0e0;
            font-size: 0.7rem;
        }

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .flag-dropdown {
        border-radius: 8px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border: 1px solid #bbbcbd !important;

        .selected-flag {
            overflow: hidden !important;
            background-color: #f3f4f6 !important;
            background-color: #f3f4f6 !important;
            border-radius: 8px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            &.open,
            &:hover {
                background-color: #f3f4f6 !important;
                border-radius: 8px !important;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }
}

.errorMessage {
    margin: 0;
    font-size: 0.7775rem;
    text-align: center;
    font-family: Urbanist, sans-serif;
    font-weight: 400;
    line-height: 1.66;
    margin-top: 0.5rem;
    color: #f44336;
    display: block;
}