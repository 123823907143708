body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  position: relative;
}

html,
body {
  height: 96%;
  background-color: white !important;
  /* Needed for container's min-height  */
}

@font-face {
  font-family: 'Lato-Light';
  src: local('Lato-Light'), url(./fonts/Lato-Light.ttf) format('truetype');
}


@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(./fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Urbanist';
  src: url('./fonts/Urbanist-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./fonts/Urbanist-SemiBold.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./fonts/Urbanist-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./fonts/Urbanist-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./fonts/Urbanist-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist-SemiBold';
  src: url('./fonts/Urbanist-SemiBold.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Urbanist-Bold';
  src: url('./fonts/Urbanist-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}



@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url(./fonts/Lato-Bold.ttf) format('truetype');
}