.countryPhone {
    .react-tel-input {
        border: none;
        width: 100%;
        color: #6b6b6b;
    }

    input {
        border-radius: 8px !important;
        padding: 0.4rem 0.7rem;
        width: 100% !important;
        height: auto !important;
        outline: none;
        border: none !important;
        box-shadow: 0 0 0 1px #e0e0e0;
        font-family: 'Urbanist', sans-serif !important;
        transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);

        &:active,
        &:focus {
            box-shadow: 0 0 0 1px #ff6601;
        }

        &::placeholder {
            color: #e0e0e0;
            font-size: 0.7rem;
        }

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .flag-dropdown {
        border-radius: 8px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border: none !important;

        .country-list {
            position: fixed;
        }
        .selected-flag {
            overflow: hidden !important;
            background-color: #f3f4f6!important;
            background-color: #f3f4f6!important;
            border-radius: 8px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            &.open,
            &:hover {
                background-color: #f3f4f6!important;
                border-radius: 8px !important;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }
}