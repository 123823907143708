.logoImage {
  width: 14rem;
  margin-bottom: 3rem;
}
@media only screen and (max-height: 830px) {
  .logoImage {
    width: 12rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-height: 670px) {
  .logoImage {
    width: 10rem;
    margin-bottom: 1rem;
  }
}
