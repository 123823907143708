.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form-control {
  position: relative;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 1rem !important;
  margin-left: 0;
  background: #FFFFFF;
  border: 0px solid #CACACA !important;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 280px;
  outline: none;
  color: #000;
  font-size: 1rem;
  font-family: Lato-Bold;
}

@media only screen and (max-width: 380px) {
  .form-control {
    width: 240px !important;
  }
}

@media only screen and (max-width: 350px) {
  .form-control {
    width: 240px !important;
  }
}

@media only screen and (max-width: 330px) {
  .form-control {
    width: 230px !important;
  }
}

@media only screen and (max-width: 320px) {
  .form-control {
    width: 220px !important;
  }
}

@media only screen and (max-width: 312px) {
  .form-control {
    width: 200px !important;
  }
}

@media only screen and (max-width: 300px) {
  .form-control {
    width: 180px !important;
  }
}


.Dropdown-control {
  border: 1px solid #AEAEB8 !important;
  padding: 1rem 1rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 350px !important;
  margin: 1rem 0;
}

.Dropdown-arrow {
  position: relative !important;
  right: 0rem !important;
  top: 0rem !important;
}

@media only screen and (max-width: 355px) {
  .Dropdown-control {
    width: 260px !important;
  }
}



.Dropdown-option {
  color: #000;
  font-size: 1rem;
  font-family: Lato-Regular;
}

.Dropdown-menu {
  border: 0px !important;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.0) !important;
  margin-top: -1rem;
}

.Dropdown-option.is-selected {
  background-color: #EBE6E6 !important;
  color: #000;
}

canvas {
  margin: 0 auto;
}

.version{
  color: rgb(141, 148, 170);
  font-size: 0.775rem;
  text-align: center;
}